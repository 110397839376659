html {
  height: 100%;
}

body {
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  background-color: white;
  min-height: 100%;
}

/* carousel styles */
@import "~react-responsive-carousel/lib/styles/carousel.min.css";

.control-dots {
  padding: 0;
}

.carousel .control-dots .dot {
  outline: none;
}
